import React, { useState, useEffect } from "react";
import { guid } from "@solimat/solimat-web-endpoint/lib/appointment/dto";
import PageMenu from "../../../components/blocks/PageMenu/PageMenu";
import TableAppointment from "../../blocks/TableAppointment/TableAppointment";
import "./appointmentDetail.scss";
// import CommunicationsBlock from "../../blocks/CommunicationOpening/CommunicationOpening";
// import Message from "../../blocks/Message/Message";
// import FormCreateCommunications from "../../blocks/Forms/FormCreateCommunication";
// import FormAnswerCommunications from "../../blocks/Forms/FormAnswerCommunication";
// import MapGoogle from "../../blocks/MapGoogle/MapGoogle";
import { TextMessagesTemplates } from "../../blocks/TextMessagesTemplates/TextMessagesTemplates";
import Modal from "../../blocks/Modal/Modal";
// import IMGnotice from "../../../img/icons/svg/popups/notice.png";
import IconArrowWhite from "../../../img/icons/svg/botones/flecha-big-blanco.svg";
// import IconCommentGray from "../../../img/icons/svg/botones/comentarios-gris.svg";
import useModal from "../../../hooks/useModal";
import useGetUserChatCommunicationByIdAppointment from "../../../services/communications/useGetUserChatCommunicationByIdAppointment";
import useSendMessageUserToManager from "../../../services/communications/useSendMessageUserToManager";
import useCreateUserCommunicationAppointment from "../../../services/communications/useCreateUserCommunicationAppointment";
import useGetAppointmentById from "../../../services/appointments/useGetAppointmentById";
import { IAppointmentResponse } from "@solimat/solimat-web-endpoint/lib/appointment/dto/Appointment/AppointmentResponse";
import Moment from "moment";
import HealthcareDocumentsGrid from "../../blocks/Grids/HealthcareDocumentsGrid";
import MapGoogle2 from "components/blocks/MapGoogle/MapGoogle2";
// import CommunicationsGridAppointment from "../../blocks/Grids/CommunicationsGridAppointment";
import useGetDocumentsByAppointmentId from "services/appointments/useGetDocumentsByAppointmentId";
import useGetRequestDocumentOtp from "services/record/useGetRequestDocumentOtp";
import FormValidateOTP from "components/blocks/Forms/FormValidateOTP";
import useModals2 from "hooks/useModal2";
import LoaderMin from "components/blocks/Loader/LoaderMin";
import useDecode from "../../../hooks/useDecode";
import { AppointmentVideoCall } from "../AppointmentVideoCall/AppointmentVideoCall";
import NoData from "../noData/NoData";
interface IvaluesFormReplyCommunication {
  externalId: string;
  userId: string;
  personId: string;
  managerId: null | string;
  appointmentId: string;
  visitId: string;
  recordId: string;
  subject: string;
  message: string;
  files?: any[];
}
interface IdataComunication {
  externalId: string;
  userId: string;
  personId: string;
  managerId: null | string;
  appointmentId: string;
  visitId: string;
  recordId: string;
  subject: string;
  message: string;
  files?: any[];
}
interface IAppointmentFormated extends IAppointmentResponse {
  formated_status: string;
  formated_start: string;
  formated_hour: string;
  communication_id: string;
  record_type: number;
  visit_type: number;
  cont_type: number;
}

const AppointmentDetail: React.FC = (props: any) => {
  const appointmentId: guid = props.match.params.id;
  const [documentID, setDocumentID] = useState<string | guid>("");
  const tokenResponse = useDecode("token");
  const userId = tokenResponse && tokenResponse.extension_id;
  const [appointment, setAppointment] = useState<IAppointmentFormated | any>({
    citaId: "1234",
    centro: {
      centroId: "1234456",
      codigoPostal: "28034",
      descripcion: "Una clínica muy buena",
      email: "centro@centro.com",
      latitud: 40.473333,
      longitud: -3.62,
      letra: "A",
      localidad: "Madrid",
      nombre: "Clínica Central",
      nombreCalle: "Calle Falsa",
      numeroCalle: "12",
      piso: "2",
      provincia: "Madrid",
      telefono: "606606959",
      tipoCalle: "Calle",
    },
    doctor: {
      email: "felipe@doctor.com",
      nombre: "Felipe",
      telefono: "606606959",
    },
    especialidad: "Especialidad",
    estado: "",
    fecha: "2045-12-05T00:00:00",
    formated_hour: "08:33",
    formated_item_type: undefined,
    formated_start: "16/01/2023",
    id: "30c5ebdd-e0fc-4fe2-8663-5e3e861d89fa",
    mensaje: "Cita en el futuro lejano",
    nombrePrueba: "Nombre de la prueba",
    personaId: "1234",
    realizada: false,
    reprogramada: false,
    tipoActividad: "Tipo",
    userId: "30c5ebdd-e0fc-4fe2-8663-5e2e860d88fa",
  });
  const [breadcrumbs, setBreadcrumbs] = useState<string[]>([]);
  const {
   
    handleCloseModal,
    
  } = useModal();
  const {
    collapsed2,
    handleOpenModal2,
    handleCloseModal2,
    handleDisableCloseButton2,
    disabledCloseButton2,
  } = useModals2();

  const {
    getUserChatCommunicationByIdAppointment,
    userChatCommunicationAppointment,
   
  } = useGetUserChatCommunicationByIdAppointment();

  const {
    createUserCommunicationAppointment,
    errorCreateUserCommunicationAppointment,
  } = useCreateUserCommunicationAppointment();

  // const { sendMessageUserToManager } = useSendMessageUserToManager();

  const {
    getAppointmentById,
    loadingGetAppointmentById,
    errorGetAppointmentById,
    dataAppointment,
  } = useGetAppointmentById();
  // const subjectsFromComunication =
  //   userChatCommunicationAppointment &&
  //   userChatCommunicationAppointment.map((sub: any, i: number) => {
  //     return sub.Subject;
  //   });
  const { documentsByAppointmentId, getDocumentstByAppointmentId } =
    useGetDocumentsByAppointmentId();
  const { getRequestDocumentOtp, errorGetRequestDocumentOtp } =
    useGetRequestDocumentOtp();
  // const subjectForAnswer =
  //   subjectsFromComunication &&
  //   subjectsFromComunication[subjectsFromComunication.length - 1];
  useEffect(() => {
    if (breadcrumbs.length < 1 && appointment.citaId !== "") {
      setBreadcrumbs(["Mis citas", "Cita"]);
 }
    handleGetAppointment();

    // getUserChatCommunicationByIdAppointment(userId, appointmentId);
    return () => {
   
      handleGetAppointment();
      // getUserChatCommunicationByIdAppointment(userId, appointmentId);
    };
  }, []);

  const handleGetAppointment = () => {
    getAppointmentById({ Id: appointmentId }, setAppointment);
  };

  // useEffect(() => {
  //   // AFTER SET APPOINTMENT IF APPOINTMENT HAS COMMUNICATION
  //   if (
  //     appointment.communication_id !== null &&
  //     appointment.communication_id !== "" &&
  //     appointment.communication_id !== undefined
  //   ) {
  //     getUserChatCommunicationByIdAppointment(userId, appointmentId);
  //   }
  //   // AFTER SET APPOINTMENT IF THERE ARE BREADCRUMBS
  //   if (breadcrumbs.length < 1 && appointment.citaId !== "") {
  //     setBreadcrumbs(["Mis citas", "Cita"]);
  //   }
  //   if (appointment) {
  //     getDocumentstByAppointmentId(
  //       appointment?.userId,
  //       appointment?.prestacionId,
  //       appointment?.citaId
  //     );
  //   }
  // }, [appointment]);

  
  const redirect = (latitud: number, longitud: number) => {
    window.open(`https://maps.google.com/?q=${latitud},${longitud}`);
  };
  const isPending =
    parseInt(Moment(appointment.fecha).format("Y MM DD").replace(/\s+/g, "")) >=
    parseInt(Moment().format("Y MM DD").replace(/\s+/g, ""));

  const convertBase64 = async (files: any) => {
    var arrayAuxiliar: any = [];
    Array.from(files).forEach((file: any) => {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        let prevExtension = file.name.split(".");
        var base64I = reader.result as string;
        arrayAuxiliar.push({
          id: "61bd8783-0f25-48bd-87fc-3d1a16ca51bb",
          name: file.name,
          extension: prevExtension.pop(),
          externalUri: "",
          base64: base64I.split(",")[1],
          externalId: "",
        });
      };
    });
    return await arrayAuxiliar;
  };

  // ADD FILES TO COMMUNICATION
  // const onSubmitCommunication = async (values: any) => {
  //   const dataFiles = await convertBase64(values["loadfiles[]"]);
  //   //send communication
  //   setTimeout(() => {
  //     const newDataJson: IdataComunication = {
  //       externalId: "",
  //       userId: appointment.userId,
  //       personId: "",
  //       managerId: null,
  //       appointmentId: appointment.id,
  //       visitId: null,
  //       recordId: null,
  //       subject: values.subject,
  //       message: values.comment,
  //       files: dataFiles,
  //     };
  //     createUserCommunicationAppointment(
  //       appointment.id,
  //       newDataJson,
  //       handleRefreshData
  //     );
  //     handleCloseModal();
  //   }, 700);

  //   // redirect
  // };

  // const onSubmitReply = async (values: any) => {
  //   const dataFiles = await convertBase64(values["loadfiles[]"]);
  //   setTimeout(() => {
  //     const newDataJson: IvaluesFormReplyCommunication = {
  //       externalId: "",
  //       userId: appointment.userId,
  //       personId: "",
  //       managerId: null,
  //       appointmentId: appointment.id,
  //       visitId: null,
  //       recordId: null,
  //       subject: values.subject,
  //       message: values.comment,
  //       files: dataFiles,
  //     };
  //     //   sendMessageUserToManager(newDataJson, () =>
  //     //   getUserChatCommunicationByIdAppointment(appointment.id)
  //     // );
  //     createUserCommunicationAppointment(appointment.id, newDataJson, () =>
  //       getUserChatCommunicationByIdAppointment(userId, appointment.id)
  //     );

  //     handleCloseModal();
  //   }, 700);
  // };
  const handleRequestDocument = (documentID: string) => {
    setDocumentID(documentID);
    handleOpenModal2();
    getRequestDocumentOtp(
      appointment?.userId,
      appointment?.prestacionId,
      documentID
    );
  };
  // const handleRefreshData = (communicationId: guid) => {
  //   setAppointment({ ...appointment, communication_id: communicationId });
  // };

  const handleGoBack = () => {
    props.history.goBack();
  };
  const [messageCollpased, setMessageCollapsed] = useState(true);
  useEffect(() => {
    if (errorCreateUserCommunicationAppointment) {
      setMessageCollapsed(false);
    }
  }, [errorCreateUserCommunicationAppointment]);
  // const handleCloseMessage = () => {
  //   setMessageCollapsed(true);
  // };

  if (errorGetAppointmentById) {
    return <section>{TextMessagesTemplates.errorResponse}</section>;
  }

  if (loadingGetAppointmentById || !dataAppointment) {
    return <section>{TextMessagesTemplates.loading}</section>;
  }
  return (
    <>
      <PageMenu
        title={appointment != null ? appointment.subject : ""}
        type="photo"
        breadcrumbs={breadcrumbs}
      >
        {/* <div className="btns-header-container">
          <button onClick={handleGoBack}>
            <img src={IconArrowWhite} alt="icon arrow right" />
          </button>
        </div> */}
      </PageMenu>
      <section className="container">
        <div className="row">
          <div className="col-12">
            <h2 className="section-title">Información de la cita</h2>
          </div>
          <div className="col-lg-12">
            <TableAppointment appointment={appointment} />
          </div>
          <div className="col-md-12 map-appointment-container ">
            {appointment.linkVideoConsulta ? (
              <AppointmentVideoCall
                appointment={appointment}
              ></AppointmentVideoCall>
            ) : (
              <MapGoogle2
                latitudeCenter={appointment.centro.latitud}
                longitudeCenter={appointment.centro.longitud}
                redirect={redirect}
                zoom={18}
                title={appointment.centro.descripcion}
              />
            )}
          </div>
        </div>
      </section>
      <section className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 ">
            <h2 className="section-title">Otros datos de interés</h2>
            {appointment !== null && (
              <ul className="episode-info__list">
                {!appointment.linkVideoConsulta ? (
                  <li>
                    <span className="header">Dirección del centro:</span>
                    <span className="data">{`${appointment.centro.nombreCalle}, ${appointment.centro.numeroCalle}`}</span>
                  </li>
                ) : (
                  ""
                )}

                <li>
                  <span className="header">Teléfono del centro:</span>
                  <span className="data">{appointment.centro.telefono}</span>
                </li>
                <li>
                  <span className="header">Asistencial:</span>
                  <span className="data">{appointment.doctor.nombre}</span>
                </li>
                {/* <li>
                  <span className="header">Nombre de la prueba:</span>
                  <span className="data">
                    {appointment.nombrePrueba !== null
                      ? appointment.nombrePrueba
                      : "-"}
                  </span>
                </li> */}
                <li>
                  <span className="header">Tipo de Prestación:</span>
                  <span className="data">
                    {appointment.tipoActividad !== null
                      ? appointment.tipoActividad
                      : "-"}
                  </span>
                </li>
                <li>
                  <span className="header">Reprogramada:</span>
                  <span className="data">
                    {appointment.reprogramada === true ? "Si" : "No"}
                  </span>
                </li>
                {appointment.especialidad !== "REHABILITACION" ? (
                  <li>
                    <span className="header">Observaciones:</span>
                    <span className="data">
                      {appointment.mensaje  
                        ? appointment.mensaje
                        : "Sin observaciones"}
                    </span>
                  </li>
                ) :  (
                  ''
                )}
              </ul>
            )}
          </div>
          <div className="col-sm-12 col-md-12 mt-4">
            <h2 className="section-title">Documentos de Cita</h2>
            {!documentsByAppointmentId ? (
              // <div className="container-without-documents">
              //   <p className="without-documents">
              //     No hay disponible documentación
              //   </p>
              // </div>
              <NoData message={'No hay disponible documentación'}/>
            ) : (
              <div>
                {/* <HealthcareDocumentsGrid
                  handleRequestToken={handleRequestToken}
                  documents={documentsByAppointmentId.map((doc: any) => {
                    return {
                      nombre: doc.nombre,
                      tipoDocumento: doc.tipoDocumento,
                      company: doc.company ?? "-",
                      fecha: doc.fecha,
                      id: doc.id,
                    };
                  })}
                /> */}
                {/* <DocumentsGrid documents={documentsByAppointmentId} /> */}
                <HealthcareDocumentsGrid
                  handleRequestDocument={handleRequestDocument}
                  documents={documentsByAppointmentId.map((doc: any) => {
                    return {
                      nombre: doc.nombre,
                      extension: doc.extension,
                      company: doc.company ?? "-",
                      fecha: doc.fecha,
                      id: doc.id,
                      requiereOtp: doc.requiereOtp,
                      uri: doc.uri,
                    };
                  })}
                />
              </div>
            )}

            <Modal
              disabledCloseButton={disabledCloseButton2}
              collapsed={collapsed2}
              onClose={handleCloseModal2}
              title={"Introduzca el código"}
              subtitle={
                "Para visualizar el documento, introduzca el código que hemos enviado a su teléfono"
              }
            >
              {collapsed2 ? (
                <div className="ghost-form"></div>
              ) : (
                <>
                  {documentsByAppointmentId ? (
                    <FormValidateOTP
                      handleDisableCloseButton={handleDisableCloseButton2}
                      onClose={handleCloseModal2}
                      props={props}
                      documentID={documentID}
                      userId={appointment?.userId}
                      prestacionId={appointment?.prestacionId}
                    />
                  ) : (
                    <>
                      {errorGetRequestDocumentOtp ? (
                        <div>
                          <h3 className="modal_title">Error</h3>
                          <p className="c-center">
                            Lo sentimos, se ha producido un error
                          </p>
                        </div>
                      ) : (
                    
                        <LoaderMin />
                      )}
                    </>
                  )}
                </>
              )}
            </Modal>
          </div>
          {appointment.observations ? (
            <>
              <div className="col-12 mt-4">
                <h2 className="section-title">Observaciones</h2>
              </div>
              <div className="col-lg-6">
                <p>- {appointment.observations}</p>
              </div>
            </>
          ) : null}
        </div>
      </section>
      {/* aqui comienza comentarios de detalle consulta */}
      {/* {(isPending ||
        (!isPending &&
          userChatCommunicationAppointment &&
          userChatCommunicationAppointment)) &&
        <section className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="section-title">Comentarios</h2>
            </div>
            {errorGetUserChatCommunicationAppointment && (
              <section>{TextMessagesTemplates.errorResponse}</section>
            )}
            {loadingGetUserChatCommunicationAppointment ? (
              <section>{TextMessagesTemplates.loading}</section>
            ) : (
              <>
                {userChatCommunicationAppointment ? (
                  <div className="col-12">
                    {isPending && (
                      <div className="btns-upon-box-container">
                        <button
                          className="btn-text btn-gray"
                          onClick={handleOpenModal}
                        >
                          <img src={IconCommentGray} alt="icon reply" />
                          Responder
                        </button>
                      </div>
                    )}
                    <Message userChatCommunication={userChatCommunicationAppointment && userChatCommunicationAppointment} userId= {appointment.userId} />
                    <CommunicationsGridAppointment
                      arrData={
                        userChatCommunicationAppointment &&
                        userChatCommunicationAppointment
                      }
                      props={props}
                    />
                  </div>
                ) : (
                  <>
                    {isPending && (
                      <div className=" align-items-center">
                        <div className="col-md-12">
                          <section className="container-communication-block">
                            <CommunicationsBlock
                              handleOpenModal={handleOpenModal}
                              handleCloseModal={handleCloseModal}
                              collapsed={collapsed}
                            />
                          </section>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </>
            )}
            {errorCreateUserCommunicationAppointment && (
              <Modal collapsed={messageCollpased} onClose={handleCloseMessage}>
                <div className="confirm-message">
                  <div className="img-container">
                    <img src={IMGnotice} alt="" />
                  </div>
                  <p>
                    Lo sentimos, se ha producido un error interno. Inténtelo de
                    nuevo más tarde, gracias.
                  </p>
                  <div className="btn-resolve-container">
                    <button
                      className="btn-box btn-accent"
                      onClick={handleCloseMessage}
                    >
                      Entendido
                    </button>
                  </div>
                </div>
              </Modal>
            )}
            <Modal collapsed={collapsed} onClose={handleCloseModal}>
              <>
                {!collapsed ? (
                  <>
                    {userChatCommunicationAppointment ? (
                      <FormAnswerCommunications
                        title={"Responder"}
                        onSubmit={onSubmitReply}
                        onClose={handleCloseModal}
                        subject={subjectForAnswer}
                      />
                    ) : (
                      <FormCreateCommunications
                        title={"Crear comunicación"}
                        onSubmit={onSubmitCommunication}
                        onClose={handleCloseModal}
                      />
                    )}
                  </>
                ) : (
                  <div className="ghost-form"></div>
                )}
              </>
            </Modal>
          </div>
        </section>
        ""} */}
    </>
  );
};

export default AppointmentDetail;
