import { useState } from "react";
import { record } from "@solimat/solimat-web-endpoint";

const useUpdateIncompatibilityDocuments = () => {
  const BASE_URL = process.env.REACT_APP_PRESTACIONES_PACIENTE_PATH || "";
  const FUNCTIONS_KEY = process.env.REACT_APP_PRESTACIONES_PACIENTE_KEY || "";
  const apiRecord = new record.DocumentsService(BASE_URL, FUNCTIONS_KEY);

  const [loadingUpdateIncompatibilityDocuments, setLoadingUpdateIncompatibilityDocuments] = useState(false);
  const [successUpdateIncompatibilityDocuments, setSuccessUpdateIncompatibilityDocuments] = useState(false);
  const [errorUpdateIncompatibilityDocuments, setErrorUpdateIncompatibilityDocuments] = useState(false);

  const UpdateIncompatibilityDocuments = async (
    userId: string,
    prestacionId: string,
    formId: string,
    docId:string,
    data: any
  ): Promise<{ status: number; data?: any; error?: any }> => {
    setLoadingUpdateIncompatibilityDocuments(true);
    setSuccessUpdateIncompatibilityDocuments(false);
    setErrorUpdateIncompatibilityDocuments(false);

    try {
      const response = await apiRecord.updateIncompatibilityDocuments(userId, prestacionId, formId, docId, data);

      setLoadingUpdateIncompatibilityDocuments(false);
      setSuccessUpdateIncompatibilityDocuments(true);

      return { status: 200, data: response.data }; 
    } catch (err) {
      console.error("Error en UpdateIncompatibilityDocuments:", err);

      setLoadingUpdateIncompatibilityDocuments(false);
      setErrorUpdateIncompatibilityDocuments(true);

      return { status: 500, error: err }; 
    }
  };
  return {
    UpdateIncompatibilityDocuments,
    loadingUpdateIncompatibilityDocuments,
    successUpdateIncompatibilityDocuments,
    errorUpdateIncompatibilityDocuments,
  };
};

export default useUpdateIncompatibilityDocuments;
